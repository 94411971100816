// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-circle-graph-js": () => import("./../../../src/pages/ingredientsCircleGraph.js" /* webpackChunkName: "component---src-pages-ingredients-circle-graph-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-ingredients-vitamins-js": () => import("./../../../src/pages/ingredientsVitamins.js" /* webpackChunkName: "component---src-pages-ingredients-vitamins-js" */),
  "component---src-pages-moringa-products-index-js": () => import("./../../../src/pages/moringa-products/index.js" /* webpackChunkName: "component---src-pages-moringa-products-index-js" */),
  "component---src-pages-moringa-products-mdx-slug-js": () => import("./../../../src/pages/moringa-products/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-moringa-products-mdx-slug-js" */)
}

